<template>
  <BaseModal
    name="modal-integration"
    size="lg"
    title="Adicione a sua integração"
    @hidden="closeModal"
    @shown="openModal"
  >
    <b-row class="ml-1">
      <p class="title-item mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        Antes de tudo, selecione a plataforma que deseja integração.
      </p>
    </b-row>
    <div class="d-flex justify-content-center">
      <b-row
        class="mt-4"
        v-for="dataService in dataServices"
        :key="dataService.id"
      >
        <b-col
          cols="12"
          md="3"
          class="mr-1"
          v-if="dataService.active && dataService.name === 'MelhorEnvio'"
        >
          <div>
            <b-card
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2 custom-width-card"
              body-class="pl-0 pr-0 size-card-ml"
              @click="openIntegrationMelhorEnvio"
              v-b-tooltip="{
                title: 'Melhor Envio',
                placement: 'top',
              }"
            >
              <b-card-text class="container-logo">
                <img
                  src="@/assets/images/Melhor_Envio.png"
                  alt="melhor-envio"
                />
              </b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        Fechar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/base/Modal";
import ConnectionsService from "@/services/resources/ConnectionsService";
const service = ConnectionsService.build();

export default {
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      dataServices: [{ active: true, name: "MelhorEnvio" }],
    };
  },
  mounted(){
    this.$root.$on("revoke", (data) => {
      this.openIntegrationMelhorEnvio();
    });
  },
  methods: {
    openIntegrationMelhorEnvio() {
      this.loading = true;
      service
        .read("/melhor_envio/url-redirect")
        .then((e) => {
          console.log(e);
          this.closeModal();
          this.$root.$emit("toIntegration");
          window.open(e, "_blank", 'height=500, width=500, left='+(window.innerWidth-600)/2+', top='+(window.innerHeight-800)/2);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal() {},
    closeModal() {
        this.$root.$emit('bv::hide::modal', 'modal-integration', '#btnShow');
    },
  },
};
</script>

<style scoped lang="scss">
.size-card-ml {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.title-item {
  color: #2133d2;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}

.img-title-integration {
  width: 15.43px;
  height: 17.14px;
}

.container-logo {
  display: grid;
  justify-content: center;
  img {
    width: 100%;
    position: relative;
    top: 10px;
  }
}

.card-body {
  cursor: pointer;
}

.title-integration {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}
</style>