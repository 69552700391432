<template>
  <SiteTemplate>
    <Header :rota="'Integrações'" />
    <div>
      <Table :isMobile="isMobile" />
    </div>
  </SiteTemplate>
</template>

<script>
import Table from "@/components/TableIntegracoes.vue";
import Header from '@/components/base/Header.vue'
import SiteTemplate from '@/templates/SiteTemplate.vue'

export default {
  name: "Home",
  components: {
    SiteTemplate,
    Table,
    Header,
  },
  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
