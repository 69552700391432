<template>
  <div>
    <ModalIntegration />
    <div class="header-table">
      <b-row></b-row>

      <div class="flex-sale">
        <b-form-group label-for="type">
          <BaseButton @click="openModal" variant="primary">
            Adicionar Integração
          </BaseButton>
        </b-form-group>
      </div>
    </div>

    <b-row class="Table-header mb-1" v-if="!isMobile">
      <b-col cols="1" md="1"> #ID </b-col>
      <b-col cols="3" md="3"> Nome </b-col>
      <b-col cols="3" md="3"> Data da Int. </b-col>
      <b-col cols="2" md="2" class=""> Status </b-col>
      <b-col cols="4" md="2" class=""> Tipo </b-col>
      <b-col cols="1" md="1" class=""> Ações </b-col>
    </b-row>

    <b-row class="Table-header mb-1" v-if="isMobile">
      <b-col cols="1" md="1"> #ID </b-col>
      <b-col cols="4" md="4"> Nome </b-col>
      <b-col cols="3" md="3"> Data</b-col>
      <b-col cols="3" md="3" class=""> Status </b-col>
    </b-row>

    <template v-if="!loading && integrations.length > 0 && isMobile">
      <b-row class="Table-body" v-for="item in integrations" :key="item.id">
        <b-col cols="1" md="1" >
          {{ item.id }}
        </b-col>

        <b-col cols="4" md="4" >
          {{ item.title }}
        </b-col>

        <b-col cols="3" md="3" >
          {{ item.created_at | datetime }}
        </b-col>

        <b-col cols="3" md="3" class="status">
          <div>
            {{ convertStatus(item.status).name }}
          </div>
        </b-col>
      </b-row>
    </template>


    <template v-if="!loading && integrations.length > 0 && !isMobile">
      <b-row class="Table-body" v-for="item in integrations" :key="item.id">
        <b-col
          cols="1"
          md="1" 
          id="id_venda"
          v-b-tooltip="{
            title: item.status,
            placement: 'bottom',
          }"
        >
          # {{ item.id }}
        </b-col>

        <b-col cols="3" md="3" >
          {{ item.title }}
        </b-col>

        <b-col cols="3" md="3" >
          {{ item.created_at | datetime }}
        </b-col>

        <b-col cols="2" md="2" class="status">
          <div>
            <svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3" cy="3" r="3" :fill="convertStatus(item.status).color" />
            </svg>

            {{ convertStatus(item.status).name }}
          </div>
        </b-col>

        <b-col cols="1" md="1">
          <img
            v-if="item.type === 'melhor_envio'"
            class="img-table"
            src="@/assets/images/Melhor_Envio.png"
            alt="melhor-envio"
          />
        </b-col>

        <b-col cols="1" md="1">
          <BaseButton @click="revokeToken(item)" v-if="item.status === 'outdated'" variant="black">
            Renovar Integração
          </BaseButton>
        </b-col>
      </b-row>
    </template>

    <!-- <b-row>
      <b-col>
        <Paginate
          v-if="integrations.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row> -->

    <div
      v-if="loading && !integrations.length"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>

    <b-row
      v-if="!loading && !integrations.length"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">Nenhum pedido foi encontrado</p>
    </b-row>
  </div>
</template>

<script>
import Paginate from "@/components/base/Paginate";
import ModalIntegration from "@/components/ModalIntegracoes";
import Money from "@/mixins/money";
import ConnectionsService from "@/services/resources/ConnectionsService";
const service = ConnectionsService.build();

export default {
  props:['isMobile'],
  components: {
    Paginate,
    ModalIntegration,
  },
  mixins: [Money],
  data() {
    return {
      intervalid1: "",
      search: "",
      loading: false,
      integrations: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  mounted() {
    this.fetchIntegrations();
    this.$root.$on("toIntegration", () => {
      this.emitInterval();
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
  },
  methods: {
    setLogo() {},
    convertStatus(e) {
      if (e === 'published') {
        return { name:"Publicado", color:"#2133D2" };
      }else if(e === 'outdated'){
        return { name:"Expirado", color:"#ff0c37" };
      }
    },
    emitInterval() {
      this.intervalid1 = setInterval(
        function () {
          this.fetchIntegrations('notloading');
        }.bind(this),
        3000
      );
    },
    openModal() {
      this.$root.$emit("bv::show::modal", "modal-integration", "#btnShow");
    },
    fetchIntegrations(loading) {
      if(!loading){
        this.loading = true;
      }
      service
        .read("?type=melhor_envio")
        .then((e) => {
          console.log(e);
          this.integrations = e;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    revokeToken(data) {
      localStorage.setItem('revoke',data.id);
      this.$root.$emit("revoke", data);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchIntegrations();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchIntegrations();
    },
  },
};
</script>

<style lang="scss" scoped>
img.img-table {
  width: 75px;
}
</style>